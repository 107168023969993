import React, { useState, useRef, useEffect } from 'react';
import QrReader from 'modern-react-qr-reader'
import { db } from './firebase';
import { set, ref, query, orderByValue, onValue, get, orderByChild, equalTo } from 'firebase/database';
import QRCode from "qrcode"
import swal from 'sweetalert';
import { reactLocalStorage } from 'reactjs-localstorage';
import { async } from '@firebase/util';
import { useNavigate } from 'react-router-dom';
// import Gif from "../assets/images/whotnow_coin.gif"
import Congratulation from "../Pages/congratulation/index"
import Loader from './Loader';
import { setHomeData } from '../redux/reducer/HomeData';
import { useDispatch } from 'react-redux';

const Test = (props) => {

  const dispatch = useDispatch()
  const navigate = useNavigate();

  const [allData, setAllData] = useState([])
  const [count, setCount] = useState(1)
  const [data, setData] = useState("");
  const [QrData, setQrData] = useState("");
  const [user, setUser] = useState("")
  const [camera, setCamera] = useState(true)
  const [image, setImage] = useState(false)
  const [qrIndex,setQrindex] = useState(0)

  useEffect(() => {
    fetchQrData()
  }, [])

  useEffect(()=>{
    fetch()

  },[qrIndex])
  const fetch = () => {
    const starCountRef = ref(db, 'qr');
    // const starCountRef = ref(db, 'chatbox/');
    onValue(starCountRef, snapSort => {
      const data = snapSort.val()
      console.log(data)
      let Alldata1 = []
      for (const item in data) {
        Alldata1 = [...Alldata1, { id: item, ...data[item] }]
      }
      setAllData(Alldata1)
      if(Alldata1[0].data){
        console.log("ind",qrIndex)
        // dispatch(setHomeData(Alldata1[qrIndex]))
        // reactLocalStorage.set("length",Alldata1.length)

        props.Qr(Alldata1[qrIndex].data)

      }

      console.log("ddd", Alldata1);
    })

  }
  // const [scan,setScan]= useState(false)
  const qrRef = useRef(null);

  // const 

  const reference = ref(db, "qr")
  const handleErrorFile = (error) => {
    console.log(error);
  }
  const handleScanFile = async (result) => {
      if (result) {
        console.log("res", result)
        setData(result);

        let res = await get(query(ref(db, "qr"), orderByChild("id"), equalTo(result)))
        console.log("firebase", res.val())
        if (res.val()) {
          //  alert("You can Proceed Now")
          const data = res.val()
          console.log(res)
          let Data = []
          for (const item in data) {
            Data = [...Data, { id: item, ...data[item] }]
          }

          console.log("fire", Data)


          if (Data[0].count == count) {
            // if (result.text == Data[count].data) {
            setImage(true)
            let filter = allData.filter((item)=>item.count==count+1)
                          console.log("filter",filter)

            if(filter !=""){
              dispatch(setHomeData(filter))
              reactLocalStorage.setObject("HomeData",filter)
            }
          
           
            // swal("Thank You", "You are eligible", "success")
            // alert("passed  Qr,,,Scan Next Qr ")
            setQrData(Data[0].data)
            console.log("aaa",Data)
            console.log(qrIndex)
            if(qrIndex <allData.length-1){
              console.log("perform")
              let index = parseInt(qrIndex + 1) 
              console.log(index)
              props.Qr(allData[index].data)
              setQrindex(index)
              reactLocalStorage.set("index",index)

            }
            // swal(allData[count].message, " ", "success");

            setCount(s => s + 1)
            let Newcount = parseInt(count + 1)
            reactLocalStorage.setObject("QrData", Data[0].data)
            reactLocalStorage.setObject("count", Newcount)
            props.countChange(Newcount)
            // }
            setTimeout(() => {
              setImage(false)
              navigate("/home")
            }, 4000)
          }
          else {
            swal("Wrong Qr", "Please sacn right Qr", "error")
            // alert("Wrong Qr")
          }

        }



        // const mostViewedPosts = query(ref(db, 'qr1'), orderByValue(result.text));
        // console.log("fire",mostViewedPosts)

      }
   

  }


  const pageOpen = () => {
    window.open(QrData.data, "_blank")

  }
  const fetchQrData = async () => {
    let user = reactLocalStorage.get("user")
    if (user) {
      setUser(user)
    }
    let Qr = reactLocalStorage.get("QrData")
    let count = parseInt(reactLocalStorage.get("count"))
    let index= reactLocalStorage.get("index")
    if (Qr) {
      setQrData(Qr)
    }
    if (count) {
      setCount(count)
    }
    if(index){
      setQrindex(parseInt(index) )
    }
  }

  if (image == false) {
    return (

      <>
        
          
            <QrReader
              facingMode={camera ? "environment" : "user"}
              delay={300}
              style={{ width: '100%', height: '400', background: '#000' }}
              onError={handleErrorFile}
              onScan={handleScanFile} />
              <button className='change_camera_btn' onClick={() => setCamera(!camera)}>更換鏡頭</button>

          
        

      </>
    );
  } else {
    return <Loader />
  }

};
export default Test