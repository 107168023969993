import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Home from "../src/Pages/Home"
import QrCode from "../src/Pages/QrCode"
import Login from "../src/Pages/Authentication/Login"
import Signup from "../src/Pages/Authentication/Signup"
// import "./assets/css/qr-code.css"
// import "./assets/css/sighup.css"
import "./assets/css/style.css"
// import "./assets/css/welcome.css"
import Qradd from './components/Qradd';
import { reactLocalStorage } from 'reactjs-localstorage';
import { useDispatch } from 'react-redux';
import { setHomeData } from './redux/reducer/HomeData';


function App() {
  const dispatch= useDispatch()

  useEffect(()=>{
let home = reactLocalStorage.get("HomeData")
console.log("home",home)
if(home){
dispatch(setHomeData(JSON.parse(home)))
}
  },[])
  return (
    <React.Fragment>
 <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/login" element={<Login />} />
          <Route path="/sign-up" element={<Signup />} /> */}
          <Route path="/home" element={<Home />} />
          <Route path="/qr-code" element={<QrCode />} />
          {/* <Route path='/qr-scan' element={<QrScanner/>}/> */}
         {/* <Route path='/add' element={<Qradd/>}/> */}

        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
