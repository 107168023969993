import { async } from '@firebase/util'
import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import { reactLocalStorage } from 'reactjs-localstorage'
import { useNavigate } from 'react-router-dom';
import Logo from "../assets/images/logo.png"


function Header() {
  const navigate = useNavigate();

  useEffect(() => {
    fetch()
  }, [])
  const fetch = async () => {
    let user = reactLocalStorage.get("user")
    setUser(user)

  }
  const [user, setUser] = useState("")
  const logOut = async () => {
    reactLocalStorage.remove("user")
    reactLocalStorage.remove("QrData")
    reactLocalStorage.remove("count")
    reactLocalStorage.clear()
    fetch()
    navigate('/home');

  }
  const moveToHome =()=>{
    navigate('/home');

  }

  return (
    <>
      <header>
    <div className='header-flex'>
    <img className='logo' src={Logo} alt="logo" onClick={moveToHome}/>

<ul>

      <li>
        <Link to="/home">HOME</Link>
      </li>
      
</ul>
    </div>
      
        <div className="clear" />
      </header>

    </>
  )
}

export default Header