import React, { Fragment } from 'react'
import ReactHowler from 'react-howler'
import congratulation from "../../assets/audioClip/congrat.wav"
import Modal from 'react-modal';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
// Modal.setAppElement('#root');

function Index({open}) {
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(open?open:false);

    function openModal() {
        setIsOpen(true);
    }

    // function afterOpenModal() {
    //     // references are now sync'd and can be accessed.
    //     subtitle.style.color = '#f00';
    // }

    function closeModal() {
        console.log("false")
        setIsOpen(false);
    }
const taskCompleted =()=>{
    // alert("jj")
    window.open("https://forms.gle/Dn3c3USTmWyi8qbT8",'_blank');
}
    return (
        <Fragment>
            <ReactHowler
                src={congratulation}
                playing={true}
                // html5 = {true}
                
            />
            <Modal
                isOpen={modalIsOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                {/* <div className="modal" tabindex="-1" role="dialog"> */}
                    <div className="modal-dialog"  >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Congratulation !!!</h5>
                                {/* <button type="button" className="close" style={{cursor:"pointer"}} onClick={closeModal}  >
                                    <span aria-hidden="true" >&times;</span>
                                </button> */}
                            </div>
                            <div className="modal-body">
                                <button className='btn btn-success'onClick={taskCompleted}  >Show me the next task.</button>
                            </div>
                            <div className="modal-footer">
                                {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                                {/* <button type="button" className="btn btn-secondary" onClick={closeModal} data-dismiss="modal">Close</button> */}
                            </div>
                        </div>
                    </div>
                {/* </div> */}

            </Modal>

        </Fragment>
    )
}

export default Index