import React, { useEffect, useState } from 'react'
import Footer from '../../common/Footer'
import Header from '../../common/Header'
import banner from "../../../src/assets/images/QR_code_for_mobile_English_Wikipedia.svg.png"
import { Link } from "react-router-dom"
import QrScanner from '../../components/QrScanner'
import { reactLocalStorage } from 'reactjs-localstorage'
import hints from "../../assets/images/hints.png"
import round from "../../assets/images/round.png"
import Blueround from "../../assets/images/blueRound.png"
import $ from "jquery"
import { async } from '@firebase/util'



function Index() {

  const fetch = async () => {
    let user = reactLocalStorage.get("user")
    console.log(user, "qr")
    if (user) {
      setUser(user)

    }
    let Qr = reactLocalStorage.get("QrData")
    if (Qr) {
      // setQrData(Qr)
      setCount(reactLocalStorage.get("count"))
    }


  }
  const [user, setUser] = useState("")
  const [qrData, setQrData] = useState("")
  const [count, setCount] = useState()

  const setQr = (data) => {
    setQrData(data)
  }
  const pageOpen = () => {
    if (qrData) {
      // window.open(qrData, "_blank")
    }
    else {
      return false
    }

  }

  const countChange = (data) => {
    setCount(data)
  }
  useEffect(() => {
    fetch()
    openModal()
  }, [])

  const Images =[
    
  ]

  const openModal =()=>{
    
  
  }
  return (
    <>
      <Header />
      <section className="banner-qr">
        <div className="wrap">
          <div className="box-article" style={{marginTop: 0}}>
            {/* <h2>Scan QR Code</h2> */}
            {/* this area  */}
            
                {/* <h4 onClick={pageOpen} style={{ cursor: "pointer" }}>{qrData}</h4> */}
                <div className="status">
                  <div className="statusItem">
                  {/* <button > */}

                    <img src={hints} alt="img" className="hintImage"  data-toggle="modal" data-target="#exampleModalCenter" />
                    {/* </button> */}

                  </div>
                  <div className="statusItem ImageSize">
                    <img src={Blueround} alt="img" className='image__sizedefine'/>
                    <img src={count  >= 2  ?Blueround:round} alt="img" className='image__sizedefine'/>
                    <img src={count  >= 3 ?Blueround:round} alt="img" className='image__sizedefine'/>
                    <img src={count  >= 4 ?Blueround:round} alt="img" className='image__sizedefine'/>
                    <img src={count  >= 5 ?Blueround:round} alt="img" className='image__sizedefine'/>
                    {/* <img src={count  >= 6 ?Blueround:round} alt="img" className='image__sizedefine'/> */}
                    {/* <img src={count  >= 7 ?Blueround:round} alt="img" style={{height:20, width:20, marginRight: 5, marginTop: -25}}/>
                    <img src={count  >= 8 ?Blueround:round} alt="img" style={{height:20, width:20, marginRight: 5, marginTop: -25}}/>
                    <img src={count  >= 9  ?Blueround:round} alt="img" style={{height:20, width:20, marginRight: 5, marginTop: -25}}/>
                    <img src={count  >= 10 ?Blueround:round} alt="img" style={{height:20, width:20, marginRight: 5, marginTop: -25}}/> */}
                  </div>
                </div>       
                {/* <h4> Collected Qr : {count ? count - 1 : 0}/10 </h4> */}
             
          </div>
          <div className="box">                   
              <QrScanner Qr={setQr} countChange={countChange} />
          </div>
        </div>
      </section>

      {/* ----Modal area---- */}

      {/* <!-- Modal --> */}
      <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">Hint</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <p onClick={pageOpen} style={{ cursor: "pointer" }}>{qrData}</p>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              {/* <button type="button" class="btn btn-primary">Save changes</button> */}
            </div>
          </div>
        </div>
      </div>

      {/* <Footer /> */}

    </>
  )
}

export default Index