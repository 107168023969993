import React, { Fragment } from 'react'
import Gif from "../assets/images/bell.gif"
import ReactHowler from 'react-howler'
import music from "../assets/audioClip/music.wav"
import Modal from 'react-modal';
import Bell from "../assets/audioClip/Bell.wav"
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px'


    

    
  },
  overlay: {
    
    background: "rgba(255, 255, 255, 1)"
  }};
function Loader() {
  return (
    <Modal
      isOpen={true}
      // onAfterOpen={afterOpenModal}
      // onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal">
      <ReactHowler
        src={Bell}
        playing={true}
      />
      <img src={Gif}  style={{maxHeight:"100vh",maxWidth:"100%"}}  />
    </Modal>)
}

export default Loader