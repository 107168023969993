import { createSlice } from '@reduxjs/toolkit'

const HomeData = createSlice({
  name: 'HomePageData',
  initialState: {
    data:[
     
    ]
  },
  reducers: {
    setHomeData:(state,action)  => {  
      state.data =action.payload
    },
    
     }
})

export const {setHomeData} = HomeData.actions
export default HomeData.reducer
