import React, { useEffect, useState } from 'react'
import Footer from '../../common/Footer'
import Header from '../../common/Header'
import welcome from "../../../src/assets/images/Banner.png"
import {Link} from "react-router-dom"
import { useSelector } from 'react-redux'
import { reactLocalStorage } from 'reactjs-localstorage'
import { set, ref, query, orderByValue, onValue, get, orderByChild, equalTo } from 'firebase/database';
import { db } from '../../components/firebase'
import { setHomeData } from '../../redux/reducer/HomeData'
import { useDispatch } from 'react-redux'

function Index() {
  const dispatch = useDispatch()
  const data = useSelector((state)=>state.HomeData.data)
  console.log(data)
  const [count,setCount] = useState()
  const [home,setHome]= useState(true)
  const [Alldata,setData] = useState([])
  const [length,setLength] = useState(0)
  // const [h1,setH1]= useState("‧第五關‧")
  // const [p1,setP1] = useState("我哋喺呢個攤位入面收埋咗5個QR code。")
  // const [p2,setp2] = useState(" 根據我哋比你嘅提示搵齊5個，")
  // const [p3,setp3]= useState("答啱問題就可以得到精美禮物一份！")
  // const [btn,setBtn] = useState("立即開始")
  // const [homeLine,setHomeLine] = useState("")
  // <strong style={{color:'#143768'}}>
  useEffect(()=>{
    let count = parseInt(reactLocalStorage.get("count"))
if(count){
  setCount(count)
  // fetch()
}
QrData()
  },[])

  const QrData =()=> {
    const starCountRef = ref(db, 'qr');
    // const starCountRef = ref(db, 'chatbox/');
    onValue(starCountRef, snapSort => {
      const data = snapSort.val()
      // console.log(data)
      let Alldata1 = []
      for (const item in data) {
        Alldata1 = [...Alldata1, { id: item, ...data[item] }]
      }
console.log("len",Alldata1.length)
  setLength(Alldata1.length)  
      console.log("ddd", Alldata1);
    })
  }
  const pageOpen =()=>{
    // alert("jj")
    window.open("https://forms.gle/Dn3c3USTmWyi8qbT8",'_blank');
}
const fetch = () => {
  const starCountRef = ref(db, 'qr');
  // const starCountRef = ref(db, 'chatbox/');
  onValue(starCountRef, snapSort => {
    const data = snapSort.val()
    console.log(data)
    let Alldata1 = []
    for (const item in data) {
      Alldata1 = [...Alldata1, { id: item, ...data[item] }]
    }
    
   let filter =Alldata1.filter(item=>item.count==1)
   console.log(filter)
   if(filter){
    setHome(false)
    dispatch(setHomeData(filter))
    reactLocalStorage.setObject("HomeData",filter)


   }

    console.log("ddd", Alldata1);
  })

}
  return (
    <>
    <Header/>
    <section className="banner">
  <div className="wrap-wel">
    <div className="box-lorem">
      {reactLocalStorage.get("HomeData")==undefined && home ?
      <><p>歡迎嚟到我哋<strong style={{color:"#143768"}}>啟德體育園</strong>嘅攤位呀！</p>
 <p>我哋喺呢個攤位入面收埋咗5個QR code。 </p>
 <p> 根據我哋比你嘅提示搵齊5個，</p>
 <p> 答啱問題就可以得到精美禮物一份！</p>
 <a href='#' className="qr-code" onClick={fetch}>立即開始 </a>
 </>:null}
  {count-1==length ?<>
  <h1>‧第五關‧</h1>
  <p>恭喜你搵到哂我哋嘅QR code呀！</p>
  <p>但係唔知你哋有冇專心留意我哋嘅展板呢？</p>
  <p>答啱問題就可以得到精美禮物一份！</p>
  <a href='#' className="qr-code" onClick={pageOpen}>立即開始</a>

  </>:<> {data.map((item)=>{
     return <>
     {item.h1 && <h1> { item.h1}</h1>}
      {item.l1 &&<p> { item.l1 } </p>}
      {item.l2 &&<p>{item.l2}</p>}
      {item.l3 &&<p>{item.l3}</p>}
      {item.l4 &&<p>{item.l4}</p>}
     {<Link className="qr-code" to="/qr-code">
        {item.btn}
      </Link>} 

      </>
     })} </>}   
 
     
    </div>
    <div className="box">
      <img src={welcome} alt="" />
    </div>
  </div>
  <div className="clear" />
</section>

{/* <Footer/> */}

    </>
  )
}

export default Index